$medBreakPt: 650px;
$smallBreakpt: 400px;

:root {
    --insetShadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 3px inset;
    --basicShadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    --bg-color: #91B35D;
    --bg-color2: #D0C38F;
    --bg-color3: #399562;
    --text-background: #e0efc9;
    --accentColor: #932D2B;
    --accentColor2: #399562;
    --text-color: black;

    --container-max-width: 1000px;
    --container-med-width: 800px;
    --minTextWidth: 200px;
    --roundedCorners: 6px;
    --dark: #28292c;
    --light: #d8dbe0;
    --system-stack: Helvetica, Arial, sans-serif;
    --body-font: 'Inconsolata', var(--system-stack);
    --heading-font: 'Montserrat', var(--system-stack);
    --h1-size: 2.5rem;
    --emphasisText: 1.25rem;
    --largeText: 1.5rem;

    &.dark {
        --accentColor: #D0C38F;
        --accentColor2: #932D2B;
        --bg-color: #25282A;
        --bg-color2: #294126;
        --bg-color3: #13252e;
        --text-background: #203A46;
        --text-color: #d8dbe0;
    }
}

// ALL
body {
    min-width: 300px;
    color: var(--text-color);
    font-family: var(--body-font);
}

button {
    border-radius: var(--roundedCorners);
    font-size: 1rem;
    padding: 1rem;
    width: fit-content;
}

p {
    overflow-wrap: break-word;
}

header {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $smallBreakpt) {
        flex-wrap: wrap;
    }

    h1 {
        display: inline;
        margin-right: 1rem;
        font-size: var(--h1-size);
    }

    nav {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: $smallBreakpt) {
            order: 2;
        }


        a {
            color: var(--accentColor);
            font-weight: 700;
            font-size: var(--largeText);
            padding: 0.25rem;
            margin: 0 1rem 0 0;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                border-radius: 3px;
                background-color: var(--accentColor);
                background: linear-gradient(90deg, var(--accentColor2), var(--accentColor), var(--bg-color));
                bottom: 0px;
                left: 0;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform .3s ease-in-out;
            }

            &:hover::before {
                transform-origin: left;
                transform: scaleX(1);
            }
        }
    }
}

footer {
    background-color: var(--bg-color);
    padding: 1rem;
    text-align: center;
}

.centerText {
    text-align: center;
}

a {
    color: var(--accentColor);
    text-decoration: none;

    &:focus-visible {
        border-radius: var(--roundedCorners);
        outline: none;
        box-shadow: var(--accentColor2) 1.95px 1.95px 2.6px;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }

}

.smallLink {
    font-weight: bold;

    &:hover {
        border-radius: var(--roundedCorners);
        outline: none;
        box-shadow: var(--accentColor2) 1.95px 1.95px 2.6px;
    }
}

h1,
h2,
h3,
h4 {
    font-family: var(--heading-font);
    margin-bottom: 0;
}

h4 {
    margin: 0.25rem;
}

.togglDarkBtn {
    cursor: pointer;
    width: 70px;
    background: none;
    border: none;
    min-width: 50px;

    svg .mooned {
        transform: translate(-90px, 90px);
    }

    svg #overlay,
    body {
        transition: all .7s ease-in-out;
    }
}

.fancyTitle {
    background-color: var(--bg-color);
    border-radius: var(--roundedCorners);
    padding: 0.25rem 0.5rem;
}

.container,
.header-container {
    background-color: var(--bg-color);
    padding: 1rem;
    width: calc(100% - 2rem);
}

.container:nth-child(even) {
    background-color: var(--bg-color2);
}

.center {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    max-width: var(--container-max-width);

    h2 {
        text-align: center;
    }
}

.smallCenter {
    margin-right: auto;
    margin-left: auto;
    max-width: var(--container-med-width);

    h2 {
        text-align: center;
        font-size: 2rem;
    }

    p {
        text-align: left;
        font-size: var(--emphasisText);
        min-width: var(--minTextWidth);
    }
}

.container-odd {
    background-color: var(--bg-color2);
    padding: 1rem;
    width: 100%;

}

.roundImg {
    border: 5px solid var(--bg-color2);
    border-radius: 50%;
    max-width: 250px;
}

.infoContainer {
    text-align: center;
}

.welcomeMsg {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    min-height: calc(100vh - 139px);
}

.arrowLink {
    text-align: center;
    font-size: var(--largeText);
    font-weight: 600;
    transition: all 0.3s ease;


    &>span {
        display: block;
        font-size: 2rem;
        transition: all 0.3s ease;
    }

    &:hover {
        transform: scale(1.1);

        &>span {
            transform: scale(1.25);
        }
    }
}

.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .projectNodeLink {
        background-color: var(--bg-color3);
        border-radius: var(--roundedCorners);
        margin: 0.5rem;
        list-style: none;
        min-width: 300px;
        width: 30%;

        &:hover {
            transform: scale(1.05);
        }

        a{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .info {
                border-bottom-left-radius: var(--roundedCorners);
                border-bottom-right-radius: var(--roundedCorners);
                background-color: var(--text-background);
                padding: 1rem;
                flex-basis: 50%;
            }
    
            img,
            svg {
                align-self: center;
                max-width: 100%;
                max-height: 170px;
            }
        }

    }
    .imgLogo {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-basis: 40%;
    }
    .projectNode {
        background-color: var(--bg-color3);
        border-radius: var(--roundedCorners);
        margin: 0.5rem;
        list-style: none;
        min-width: 300px;
        width: 30%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        img,
        svg {
            align-self: center;
            max-width: 100%;
            max-height: 170px;
        }

        .info {
            border-bottom-left-radius: var(--roundedCorners);
            border-bottom-right-radius: var(--roundedCorners);
            background-color: var(--text-background);
            padding: 1rem;
            flex-basis: 50%;
        }
    }
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    &>div {
        flex: 1;
        padding: 1rem;
    }

    &>.info {
        font-size: var(--emphasisText);
        flex: 2;

        p {
            text-align: left;
            min-width: var(--minTextWidth);
        }

        .infoImage {
            img {
                width: 100%;
            }

            cite {
                font-size: 0.75rem;
                display: block;
            }
        }
    }

    &>.fancyContainer {
        flex: 1;
        background-color: var(--bg-color3);
        border-radius: var(--roundedCorners);
        box-shadow: var(--basicShadow);
        padding: 1rem;
        margin: 1rem;
        text-align: center;
        max-width: 45%;
        min-width: 40%;

        .gif {
            width: 100%;
        }

        @media only screen and (max-width: $medBreakPt) {
            min-width: 90%;
            max-width: none;
        }

        h3 {
            flex-basis: 100%;
            text-align: center;
        }
    }
}

.itemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style-type: none;
    padding-inline-start: 0;
    margin-top: 0;

    &>li {
        flex: 1;
        padding: 0 0.5em 1rem 0.5em;
        min-width: calc(47% - 1rem);

        @media only screen and (max-width: $medBreakPt) {
            min-width: 90%;
        }
    }
}

.socialLinks {
    display: flex;
    justify-content: center;

    a {
        margin: 0.25rem 1rem;
        padding: 0.25rem 0.25rem 0 0.25rem;

        &:hover,
        &:focus {
            border-radius: var(--roundedCorners);
            outline: none;

            svg {
                fill: var(--bg-color2);
            }
        }
    }
}



.hobbyNav {
    background-color: var(--bg-color3);
    border-radius: var(--roundedCorners);
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    a {
        font-size: 1rem;
        display: flex;
        padding: 0.5rem;
        font-weight: 800;
        color: var(--text-color);

        &:hover,
        &:focus {
            outline: none;

            svg {
                fill: var(--bg-color);
            }
        }
    }

    svg {
        margin: 0 0.25rem;
        fill: var(--accentColor)
    }
}

.sticky {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
}

.infoDiv {
    background-color: var(--text-background);
    box-shadow: var(--insetShadow);
    border-radius: var(--roundedCorners);
    padding: 1rem 2rem;
    margin: 0 1rem 1rem 1rem;

    h3 {
        text-align: center;
        margin-bottom: 1rem;
    }

    svg {
        display: block;
    }

    ol {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-inline-start: 0;

        li {
            counter-increment: step-counter;
            display: inline-block;
            min-width: 45%;
            padding: 0.25rem;

            &:before {
                margin-right: 10px;
                content: counter(step-counter);
                background: var(--dark);
                border-radius: 100%;
                color: var(--light);
                padding: 0.2rem;
                width: 1.2em;
                text-align: center;
                display: inline-block;
            }
        }
    }
}

blockquote {
    font-style: italic;
}

.infoDivImg {
    background-color: var(--text-background);
    box-shadow: var(--insetShadow);
    border-radius: var(--roundedCorners);
    padding: 0;
    margin: 0 1rem 1rem 1rem;
    display: flex;

    @media only screen and (max-width: $medBreakPt) {
        flex-wrap: wrap;
        justify-content: center;
    }

    div {
        padding: 1rem 2rem;
    }

    img {
        border-radius: var(--roundedCorners);
        max-width: 225px;
    }
}

.infoDivPlain {
    text-align: center;
    margin: 2rem;
}

input[type=text],
input[type=email],
textarea {
    background-color: var(--light);
    box-shadow: var(--insetShadow);
    border-radius: var(--roundedCorners);
    display: block;
    margin: 1rem 0;
    padding: 1rem;
    width: calc(100% - 2rem);
}

.contactForm {
    background-color: var(--bg-color3);
    border-radius: var(--roundedCorners);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem;
    width: 80%;

    label {
        font-size: 1rem;
        font-weight: bold;
    }
}

.myServices {
    & svg {
        flex: 1;
        fill: var(--accentColor);
        align-self: center;
    }
}

.artGallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 290px);
    gap: 0.625rem;
    justify-content: center;
    place-items: center;

    img {
        border-radius: var(--roundedCorners);
        grid-column-start: auto;
        max-width: 290px;
        max-height: 330px;
    }
}